<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <v-row class="auth-row ma-0">
        <v-col
          lg="8"
          class="d-none d-lg-block position-relative overflow-hidden pa-0"
        >
          <div class="auth-illustrator-wrapper">
            <div class="d-flex align-center h-full pa-a ma-0">
              <v-img
                position="left center"
                height="100vh"
                :src="require(`@/assets/images/pages/background.jpg`)"
              ></v-img>
            </div>
          </div>
        </v-col>

        <v-col
          lg="4"
          class="d-flex align-center auth-bg pa-10 pb-0"
        >
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="6"
              lg="12"
              class="mx-auto"
            >
              <v-card class="auth-card pa-5">
                <!-- logo -->
                <v-card-title class="d-flex align-center justify-center py-7">
                  <v-row class="w-full">
                    <v-col cols="7">
                      <router-link
                        to="/"
                        class="d-flex align-center"
                      >
                        <v-img
                          :src="require('@/assets/images/logos/logo.png')"
                          max-height="42px"
                          alt="logo"
                          contain
                          class="me-3 "
                        ></v-img>
                      </router-link>
                    </v-col>
                  </v-row>
                </v-card-title>

                <v-card-text>
                  <p class="text-2xl font-weight-semibold text--primary mb-2">
                    {{ $t('general.welcomeMessage') }}
                  </p>
                  <p class="mb-2">
                    {{ $t('login.signInMessage') }}
                  </p>
                  <v-alert
                    v-if="serverErrorMessage"
                    cols="12"
                    class="mb-2"
                    type="error"
                    v-text="$t(serverErrorMessage)"
                  >
                  </v-alert>
                  <v-alert
                    v-if="isEmailVerified"
                    cols="12"
                    class="mb-2"
                    type="success"
                    v-text="$t('login.emailVerified')"
                  >
                  </v-alert>
                </v-card-text>

                <!-- login form -->
                <v-card-text>
                  <v-form
                    ref="loginForm"
                    @submit.prevent="handleFormSubmit"
                  >
                    <v-text-field
                      v-if="isVerificationEnabled"
                      v-model="verificationCode"
                      outlined
                      label="Verification code"
                      placeholder="verification code"
                      :error-messages="errorMessages.verificationCode"
                      hide-details="auto"
                      class="mb-6"
                    ></v-text-field>

                    <v-text-field
                      v-if="!isVerificationEnabled"
                      v-model="email"
                      outlined
                      label="Email"
                      placeholder="email"
                      :error-messages="errorMessages.email"
                      :rules="[validators.required, validators.emailValidator]"
                      hide-details="auto"
                      class="mb-6"
                    ></v-text-field>

                    <v-text-field
                      v-if="!isVerificationEnabled"
                      v-model="password"
                      outlined
                      :type="isPasswordVisible ? 'text' : 'password'"
                      label="Password"
                      :error-messages="errorMessages.password"
                      placeholder="Password"
                      :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                      :rules="[validators.required]"
                      hide-details="auto"
                      class="mb-2"
                      @click:append="isPasswordVisible = !isPasswordVisible"
                    ></v-text-field>

                    <div class="d-flex align-center justify-space-between flex-wrap">
                      <v-checkbox
                        hide-details
                        label="Remember Me"
                        class="mt-0"
                      >
                      </v-checkbox>
                    </div>

                    <v-btn
                      block
                      color="primary"
                      type="submit"
                      class="mt-6"
                    >
                      Login
                    </v-btn>
                  </v-form>
                </v-card-text>

                <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
                  <p>
                    <!-- forget link -->
                    <router-link
                      :to="{name:'auth-forgot-password'}"
                    >
                      {{ $t('general.forgotPassword') }}
                    </router-link>
                  </p>
                </v-card-text>

                <!-- create new account  -->
                <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
                  <p class="mb-0 me-2">
                    {{ $t('login.newOnPlatform') }}
                  </p>
                  <router-link :to="{name:'auth-register'}">
                    {{ $t('login.createAccount') }}
                  </router-link>
                </v-card-text>

                <!-- divider -->
                <v-card-text class="d-flex align-center mt-2">
                  <v-divider></v-divider>
                  <span class="mx-5">{{ $t('general.or') }}</span>
                  <v-divider></v-divider>
                </v-card-text>

                <!-- social links -->
                <v-card-actions class="d-flex justify-center">
                  <v-btn
                    v-for="link in socialLink"
                    :key="link.icon"
                    icon
                    class="ms-1"
                  >
                    <v-icon :color="$vuetify.theme.dark ? link.colorInDark:link.color">
                      {{ link.icon }}
                    </v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiEyeOffOutline, mdiEyeOutline, mdiFacebook, mdiGoogle, mdiTwitter } from '@mdi/js'
import { getCurrentInstance, ref } from '@vue/composition-api'
import { emailValidator, required } from '@core/utils/validation'
import { useRouter } from '@core/utils'
import themeConfig from '@themeConfig'
import axiosIns from '@axios'
import { setLanguageForLoggedInUser } from '@/plugins/i18n'

export default {
  setup() {
    // Template Ref
    const loginForm = ref(null)
    const serverErrorMessage = ref('')
    const isVerificationEnabled = ref(false)
    const verificationCode = ref('')

    const vm = getCurrentInstance().proxy
    const { router } = useRouter()

    const isPasswordVisible = ref(false)

    const isEmailVerified = ref(router.history.current.query.message
      && router.history.current.query.message.toString() === 'emailVerified')
    const email = ref(router.history.current.query.email ? router.history.current.query.email.toString() : '')
    const password = ref('')
    const errorMessages = ref([])
    const socialLink = [
      {
        icon: mdiFacebook,
        color: '#4267b2',
        colorInDark: '#4267b2',
      },
      {
        icon: mdiTwitter,
        color: '#1da1f2',
        colorInDark: '#1da1f2',
      },
      {
        icon: mdiGoogle,
        color: '#db4437',
        colorInDark: '#db4437',
      },
    ]

    const handleFormSubmit = () => {
      serverErrorMessage.value = ''
      isEmailVerified.value = false
      const isFormValid = loginForm.value.validate()
      if (!isFormValid) return

      axiosIns
        .post('/auth/login', {
          email: email.value,
          password: password.value,
          verificationCode: verificationCode.value,
        })
        .then(response => {
          const { accessToken } = response.data
          const { shouldVerify } = response.data

          if (!shouldVerify) {
            localStorage.setItem('accessToken', accessToken)
          } else {
            isVerificationEnabled.value = true
          }

          return response
        })
        .then(() => {
          if (localStorage.getItem('accessToken')) {
            axiosIns.get('/auth/userInfo')
              .then(response => {
                const { user } = response.data

                const { ability: userAbility } = response.data
                vm.$ability.update(userAbility)
                localStorage.setItem('userAbility', JSON.stringify(userAbility))
                localStorage.setItem('userData', JSON.stringify(user))
                setLanguageForLoggedInUser()

                router.push('/')
              })
          }
        })
        .catch(error => {
          if (error.response.data.message) {
            serverErrorMessage.value = error.response.data.message
          }
        })
    }

    return {
      isEmailVerified,
      handleFormSubmit,
      serverErrorMessage,
      isVerificationEnabled,
      isPasswordVisible,
      verificationCode,
      email,
      password,
      errorMessages,
      socialLink,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      validators: {
        required,
        emailValidator,
      },

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      // Template Refs
      loginForm,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
</style>
